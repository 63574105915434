:global(.ant-btn).noStyleButton {
  width: 100%;
  border: none;
  box-shadow: none;
  background: none !important; // !important is used to overwrite all over states
  text-align: left;

  &.centered {
    text-align: center;
  }

  svg {
    font-size: 24px;
  }
}
