div.selection_container {
  div.selection_inner {
    margin-bottom: 40px;
  }

  h2.selection__header {
    font-family: futura-pt, sans-serif;
    font-size: 28px;
    font-weight: 700;
    font-style: normal;
  }

  h4.selection__sub_heading {
    font-family: futura-pt, sans-serif;
    font-size: 22px;
    font-weight: 300;
  }

  div.selection__button_container {
    display: grid;
    grid-template-columns: repeat(4, 100px);
    row-gap: 20px;
    align-items: flex-start;
  }

  button.additionalSelection__button {
    &:first-of-type {
      padding-left: 0;
    }
  
    p {
      font-weight: 700;
      margin-top: 10px;
      margin-bottom: 5px;
    }
  }

  div.additionalSelection__buttonBackground {
    width: 70px;
    height: 70px;
    position: relative;
  
    margin: 0 auto;
    background-size: cover;
    border-radius: 50%;
  
    &::after {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      border-radius: 50%;
    }
  }
}

div.variantSelected {
  &::after {
    border: 4px solid #fed198;
  }
}