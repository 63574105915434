.circular_button {
  width:50px;
  height:50px;    
  overflow:hidden;
  position:relative;
  border-radius: 50%;
  border: 4px solid white;
  transition: border 200ms;
  background-position: center;
  background-size: cover;

  img {
    position:absolute;
  }
}

.circular_button:hover {
  border: 4px solid #fed198;
  transition: border 200ms;
}

.circular_button_active {
  border: 4px solid #fed198;
}