@import 'assets/colours';

nav {
  display: flex;
  align-items: center;

  &.cartButton__hidden {
    ul.menu {
      margin: 0;
    }
  }

  .cartButton {
    :global(.ant-badge-count) {
      box-shadow: none;
    }

    span {
      font-weight: 750;
      font-size: 12px;
    }
  }

  ul.menu {
    list-style: none;
    margin: 0;
    margin-right: 30px;
    padding: 0;

    li {
      margin: 0 15px;
      padding: 0px 15px;
      float: left;

      a {
        text-decoration: none;
        color: #000;
        font-size: 18px;
        font-family: futura-pt, sans-serif;
        font-weight: 700;
        font-style: normal;
      }
    }
  }

  button {
    display: flex;
    align-items: center;
    font-size: 32px;
    
    :global(.ant-badge-count) {
      background-color: $elite-feature-orange;
    }
  }
}

@media only screen and (max-width: 764px) {
  footer {
    nav {
      ul.menu {
        li {
          text-align: left;
          float: none;
          margin: 0;
          padding: 0;

          a {
            font-size: 16px;
          }
        }
      }
    }
  }
}