// Styling applied to the basic page layouts
:global(.fixed__layout) {
  footer {
    width: 75vw;
    left: 25vw;
  }
}

footer {
  position: relative;
  padding: 80px;

  .address {
    margin-bottom: 80px;
  }

  .additionalPageLinks {
    display: flex;
    align-items: center;

    a {
      margin: 0;
      padding: 0 20px;
      text-transform: lowercase;
      color: initial;
      opacity: 0.2;
      font-size: 16px;
      font-family: futura-pt, sans-serif;
      font-weight: 700;
      font-style: normal;
      transition: 0.2s;

      &:first-of-type {
        border-right: 3px solid #E4E4E4;
      }

      &:hover {
        opacity: 1;
      }
    }
  }

  .footer__logoContainer {
    max-width: 120px;
  }

  .footer__container {
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

@media only screen and (max-width: 764px) {
  footer {
    padding: 80px 15px 20px 15px;

    .address {
      margin-bottom: 40px;
    }

    .footer__container {
      flex-direction: column;
      align-items: initial;
    }

    .additionalPageLinks {
      flex-direction: column;
      align-items: initial;
      margin-top: 5px;
      order: 3;

      a {
        padding: 0;
        border: none !important;
        opacity: 1;
        text-transform: none;
      }
    }
  }

  :global(.fixed__layout) {
    footer {
      width: 100%;
      left: 0;
    }
  }
}