@import 'assets/colours';

$height: 500px;

// Modal styling
.modal {
  .variant__container {
    justify-content: center;
  }
}

.product_content {
  max-width: 600px;
  margin: 0 auto;
}

.form_selection {
  width: 100%;
  max-height: $height;
  position: relative;
  overflow: hidden;
  margin-bottom: 30px;
}

.form_selection__inner {
  position: relative;
}

.form_selection__container {
  height: $height;

  div.form_selection__variant_inner {
    width: 290px;
  }

  button.form_selection__see_more {
    float: right;
    margin-bottom: 5px;
    font-weight: 700;
    opacity: 0.3;
    transition: 0.2s;

    &:hover {
      opacity: 1;
    }
  }

  p.form_selection__selected_variant {
    display: block;
    clear: both;
    font-size: 16px;
  }
}

.product__description {
  margin-bottom: 40px;
}

.variant__container {
  display: grid;
  grid-template-columns: repeat(5, 60px);
  row-gap: 20px;
  margin: 20px 0;
}

.cart_button_container {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

button.product__backButton {
  padding: 10px 0;
  margin-right: 40px;
  font-weight: 700;
  transition: 0.2s;
  opacity: 0.3;

  &:hover {
    opacity: 1;
  }
}

button.product__addCartButton {
  padding: 10px 40px;
  background: $elite-feature-yellow;
  border-bottom: 1px solid #000;
  font-weight: 700;
  transition: 0.2s;

  &:hover {
    background-color: $elite-feature-orange;
  }
}

.additionalProduct__container {
  height: 450px;
  background-color: pink;
}

div.chainActive {
  &::after {
    border: 4px solid #fed198;
  }
}