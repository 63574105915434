.cart_container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 60vh;

  .cartItems {
    width: 100%;
    margin-bottom: 80px;
  }

  h1.title {
    margin-bottom: 40px;
    font-size: 32px;
    font-family: futura-pt, sans-serif;
    font-weight: 700;
    font-style: normal;
    text-decoration: none;
    color: #000;
  }

  .back_button {
    text-decoration: none;
    color: #000;
    font-size: 30px;
    font-family: futura-pt, sans-serif;
    font-weight: 700;
    font-style: normal;
  }

  .cartPage__notesContainer {
    width: 100%;
    margin-bottom: 80px;

    h2 {
      text-align: left;
    }
  }
}

