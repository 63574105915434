/**
 * AU-replace - Replace a string with a string
 * http://codepen.io/jakob-e/pen/doMoML
 *
 * @author @eriksen_dk <https://twitter.com/eriksen_dk>
 *
 * @param  {string} $string  - The haystack string to be manipulated
 * @param  {string} $search  - The needle to be replace
 * @param  {string} $replace - The replacement
 *
 * @return {string}          - The manipulated string with replaced values
 */
 @function WHT-replace( $string, $search, $replace: '' ) {
	@if type-of( $string ) != 'string' {
		@error "I have a bad feeling about this; the AU-replace first argument must be a string!";
	}

	@if type-of( $search ) != 'string' {
		@error "We’ll always have Paris; the AU-replace second argument must be a string!";
	}

	@if type-of( $replace ) != 'string' {
		@error "Hasta la vista, baby; the AU-replace third argument must be a string!";
	}

	$index: str-index( $string, $search );

	@return if( $index,
		str-slice( $string, 1, $index - 1 ) +
		$replace +
		WHT-replace(
			str-slice( $string, $index + str-length( $search )
		), $search, $replace ),
		$string
	);
}

/**
 * Return the right SVG shape for fore- and background
 *
 * @param  {string} $kind                 - Either "checkbox" or "radio"
 * @param  {string} $part                 - Either "background" or "foreground"
 * @param  {color} $color1: transparent   - A color to be applied to the first part of the SVG shape
 * @param  {color} $color2: transparent   - A color to be applied to the second part of the SVG shape
 *
 * @return {svg}                          - The SVG shape inside a background-image
 */
 @mixin getControlShape( $kind, $part, $color1: transparent, $color2: transparent ) {
	$start: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">';
	$end: '</svg>';

	$checkbox-background: '<path fill="#{ $color1 }" d="M0,0h32v32H0V0z"/><path fill="#{ $color2 }" d="M2,2h28v28H2V2z"/>';
	$checkbox-foreground: '<path fill="#{ $color1 }" d="M25.6,11.4c0.2-0.2,0.2-0.5,0-0.7l-2.3-2.3c-0.2-0.2-0.5-0.2-0.7,0L14,17l-3.6-3.6c-0.2-0.2-0.5-0.2-0.7,0l-2.3,2.3 c-0.2,0.2-0.2,0.5,0,0.7l6.3,6.3c0.2,0.2,0.5,0.2,0.7,0L25.6,11.4L25.6,11.4z"/>';

	$radio-background: '<circle fill="#{ $color1 }" cx="16" cy="16" r="16"/><circle fill="#{ $color2 }" cx="16" cy="16" r="14"/>';
	$radio-foreground: '<circle fill="#{ $color1 }" cx="16" cy="16" r="11"/>';

	@if( $kind == 'checkbox' and $part == 'background' ) {
		background-image: WHT-svguri( $start + $checkbox-background + $end );
	}

	@if( $kind == 'checkbox' and $part == 'foreground' ) {
		background-image: WHT-svguri( $start + $checkbox-foreground + $end );
	}

	@if( $kind == 'radio' and $part == 'background' ) {
		background-image: WHT-svguri( $start + $radio-background + $end );
	}

	@if( $kind == 'radio' and $part == 'foreground' ) {
		background-image: WHT-svguri( $start + $radio-foreground + $end );
	}
}

/**
 * AU-svguri - Generate an optimized SVG data-uri
 * https://codepen.io/tigt/post/optimizing-svgs-in-data-uris
 * http://codepen.io/jakob-e/pen/doMoML
 *
 * @author @eriksen_dk <https://twitter.com/eriksen_dk>
 *
 * @param  {string} $svg - The SVG to be converted
 *
 * @return {string}      - An optimized data-uri
 */
 @function WHT-svguri( $svg ) {
	@if type-of( $svg ) != 'string' {
		@error "With great power comes great responsibility; the AU-svguri function only takes a string!";
	}

	@if not str-index( $svg, xmlns ) { // Add missing namespace
		$svg: WHT-replace( $svg, '<svg','<svg xmlns="http://www.w3.org/2000/svg"' );
	}

	$encoded:'';
	$slice: 2000;
	$index: 0;
	$loops: ceil( str-length( $svg ) / $slice ); // Chunk up string in order to avoid "stack level too deep" error

	@for $i from 1 through $loops {
		$chunk: str-slice( $svg, $index, $index + $slice - 1 );

		$chunk: WHT-replace( $chunk,'"', "'" );
		$chunk: WHT-replace( $chunk,'	', " " );
		$chunk: WHT-replace( $chunk,"\a", " " );
		$chunk: WHT-replace( $chunk,'  ', " " );
		$chunk: WHT-replace( $chunk,'%', '%25' );
		$chunk: WHT-replace( $chunk,'&', '%26' );
		$chunk: WHT-replace( $chunk,'#', '%23' );
		$chunk: WHT-replace( $chunk,'{', '%7B' );
		$chunk: WHT-replace( $chunk,'}', '%7D' );
		$chunk: WHT-replace( $chunk,'<', '%3C' );
		$chunk: WHT-replace( $chunk,'>', '%3E' );

		/*    The maybe list

			 Keep size and compile time down
			 ... only add on documented fail

		 $chunk: WHT-replace( $chunk, '|', '%7C' );
		 $chunk: WHT-replace( $chunk, '[', '%5B' );
		 $chunk: WHT-replace( $chunk, ']', '%5D' );
		 $chunk: WHT-replace( $chunk, '^', '%5E' );
		 $chunk: WHT-replace( $chunk, '`', '%60' );
		 $chunk: WHT-replace( $chunk, ';', '%3B' );
		 $chunk: WHT-replace( $chunk, '?', '%3F' );
		 $chunk: WHT-replace( $chunk, ':', '%3A' );
		 $chunk: WHT-replace( $chunk, '@', '%40' );
		 $chunk: WHT-replace( $chunk, '=', '%3D' );
		*/

		$encoded: #{ $encoded }#{ $chunk };
		$index: $index + $slice;
	}

	@return url("data:image/svg+xml,#{ $encoded }");
}

//--------------------------------------------------------------------------------------------------------------------------------------------------------------
// control inputs
//--------------------------------------------------------------------------------------------------------------------------------------------------------------
.au-control-input {
	display: inline-block;
	position: relative;
}

.au-control-input__input {
	// hiding the native element behind our SVG so for browsers that don’t support SVG have a fallback
	position: absolute;
	left: 8px;
	left: 0.5rem;
	top: 8px;
	top: 0.5rem;
	cursor: pointer;
	margin: 0;

	& + .au-control-input__text {
		cursor: pointer;
	}

	&[type=radio] {
		& + .au-control-input__text:before,
		& + .au-control-input__text:after {
			border-radius: 50%;
		}
	}

	// RADIOS
	&[type=radio] {
		// normal
		& + .au-control-input__text {
			&:before {
				@include getControlShape( 'radio', 'background', black, white );
			}

			&:after {
				background-image: none;
			}
		}

		// normal checked
		&:checked + .au-control-input__text {
			&:after {
				@include getControlShape( 'radio', 'foreground', black );
			}
		}

		// focus
		&:focus + .au-control-input__text:before {
			// Add 2px so it doesn't touch green or red
			// @include AU-space( box-shadow, 0 0 0 0.25unit $AU-color-foreground-focus );

			.ie8 &,
			.lt-ie8 & {
				// Not using AU-focus as we are not applying :focus to this element
				// @include AU-outline();
				outline-offset: 2px;
			}
		}
	}
}

/**
 * The label text
 */
.au-control-input__text {
	// @include AU-fontgrid( sm );
	// @include AU-space( padding, 0.25unit 0.5unit 0.5unit 2.5unit );
	// @include AU-space( margin-right, 1unit );
	font-size: 16px;
	font-size: 1rem;
	line-height: 1.5;
	padding: 4px 8px 8px 40px;
	padding: 0.25rem 0.5rem 0.5rem 2.5rem;
	margin-right: 16px;
	margin-right: 1rem;
	display: block;
	font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
	color: #313131;
	display: block;
	// font-family: $AU-font;

	// styling for both SVG layers
	&:before,
	&:after {
		width: 38px;
    width: 2.375rem;
    height: 38px;
    height: 2.375rem;
    content: ' ';
    display: block;
    position: absolute;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    background-repeat: no-repeat;
    z-index: 100;
    left: -3px;
    top: -3px;
    border: 3px solid transparent;

		.au-control-input--small & {
			// @include AU-space( width, 1.75unit );
			// @include AU-space( height, 1.75unit );
		}
	}

	.au-control-input--small & {
		// @include AU-space( padding, 0.2unit 0.5unit 0 1.95unit );
		// @include AU-space( margin-right, 1unit );
		// @include AU-fontgrid( sm, nospace );
	}

	&:after {
		z-index: 101;
	}
}