.productGrid__item__container {
  margin-bottom: 30px;
  padding: 10px;
  text-align: center;

  .productImage__container {
    margin-bottom: 20px;
    height: 300px;
    position: relative;
    overflow: hidden;

    img {
      width: auto;
      max-width: none;
      height: 100%;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }

    &.guide {
      img {
        filter: grayscale(100%);
      }
    }
  }

  h2 {
    font-family: serif;
  }

  p.productGrid__item__price {
    color: initial;
    font-weight: 700;
  }
}

@media only screen and (max-width: 764px) {
  .productGrid__item__container {
    padding: 0;
  }
}