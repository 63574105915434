.productsContainer {
  padding-top: 100px;
}

.shopFilter {
  display: flex;
  align-items: center;

  .shopFilter__buttonContainer {
    button {
      margin: 0;
      padding: 0;
      display: block;
      font-size: 22px;
      font-weight: 600;
      opacity: 0.2;
      transition: 0.3s;
      font-family: futura-pt, sans-serif;
      font-weight: 700;
      font-style: normal;

      &.active,
      &:hover {
        opacity: 1;
      }
    }
  }
}

@media only screen and (max-width: 764px) {
  .shopFilter {
    .shopFilter__buttonContainer {
      width: 300px;
      display: flex;
      align-items: center;
      margin: 0 auto;
      margin-bottom: 20px;
      justify-content: space-around;
    }
  }
}