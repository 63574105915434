@import 'assets/_colours';

.homeHeader {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;

  div.homeHeader__content {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
    height: 70vh;
    background-size: cover;
    background-position: center;
    z-index: 1;

    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 70%;
      background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
      z-index: -1;
    }
  }

  h1.homeHeader__title {
    margin: 0 auto;
    margin-top: 50px;
    text-align: center;
    font-size: 80px;
    line-height: 1.1;
    color: #FFF;

    span {
      position: relative;
      z-index: 2;

      // &::after {
      //   content: '';
      //   width: 100%;
      //   height: 30px;
      //   position: absolute;
      //   left: 0;
      //   bottom: 40px;
      //   background-color: $elite-feature-yellow;
      //   z-index: -1;
      // }
    }
  }

  div.homeHeader__productSlider {
    width: 100%;
    height: 450px;
    max-width: 2000px;
    margin-top: -200px;
    margin-right: -30%;
    margin-bottom: 200px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    align-self: flex-end;

    a.productSlider__link {
      display: contents;
    }

    div.productSlider__item {
      width: 40%;
      height: 100%;
      margin-left: 40px;
      display: block;
      background-size: cover;
    }
  }
}

@media only screen and (max-width: 600px) {
  .homeHeader {
    h1.homeHeader__title {
      font-size: 50px;

      span::after {
        display: none;
      }
    }
  }
}