.callToAction {
  padding: 300px 0;
  text-align: center;

  h1 {
    margin-bottom: 60px;
    font-size: 56px;
  }

  ul {
    max-width: 300px;
    margin: 0 auto;
    padding: 0;
    list-style: none;

    li {
      border-bottom: 1px solid #161717;

      &:first-of-type {
        border-top: 1px solid #161717;
      }

      button {
        width: 100%;
        padding: 30px 0;
        font-size: 18px;
        font-weight: 600;
        opacity: 0.2;
        transition: 0.3s;

        &:hover {
          opacity: 1;
        }
      }
    }
  }
}

.homePageDecoContainer {
  margin-top: 200px;
  padding: 30px;
  box-sizing: border-box;

  .homePageDecoImage {
    height: 800px;
    background-size: cover;
    background-position: center;
  }
}

.featureProducts {
  max-width: 1600px;
  padding: 150px 0;
  padding-left: 10%;

  h1 {
    font-size: 56px;
  }

  p.featureProducts__blurb {
    font-size: 20px;
    font-weight: 100;
  }
}

@media only screen and (max-width: 764px) {
  .featureProducts {
    padding: 100px 15px;

    h1 {
      font-size: 36px;
    }
  }

  .homePageDecoContainer {
    padding: 30px 15px;

    .homePageDecoImage {
      height: 500px;
    }
  }
}