div.basicPage__fixedContainer {
  position: fixed;
  top: 120px;

  div.sidebar {
    width: 25vw;
    height: 60vh;
    padding-top: 70px;
    padding-left: 80px;
    background-color: #FFF;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  p.sidebar__footnote {
    font-weight: 300;
    max-width: 450px;
  }
}

div.scrollingContainer {
  width: 100%;
  min-height: 100vh;
  padding: 80px 20px 0 30%;
  box-sizing: border-box;

  div.scrollingContainer__inner {
    margin-top: 100px;
  }

  p {
    font-size: 16px;
  }
}

@media only screen and (max-width: 764px) {
  div.basicPage__fixedContainer {
    position: static;

    div.sidebar {
      width: 100%;
      display: block;
      height: auto;
      padding: 150px 15px 0 15px;

      h1 {
        text-align: center;
        margin: 0;
        margin-bottom: 20px;
      }

      p {
        text-align: center;
      }
    }
  }

  div.scrollingContainer {
    padding: 0 15px;

    div.scrollingContainer__inner {
      margin-top: 50px;
    }
  }
}