.logoContainer {
  width: 150px;

  img {
    width: 100%;
  }
}

@media only screen and (max-width: 764px) {
  footer {
    .logoContainer {
      margin-top: 40px;
      order: 4;
    }
  }
}