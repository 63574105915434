.cartItem__container {
  position: relative;
  margin-bottom: 55px;
  border-bottom: 1px solid #F4F4F4;

  div.cartItem__imageContainer {
    width: 100%;
    height: 150px;
    margin-bottom: 15px;
    background-size: cover;
    background-position: center;
  }

  h2.cartItem__title {
    margin-bottom: 0;
  }

  p.cartItem__subTitle {
    font-weight: 200;
  }

  button.cartItem__removeButton {
    position: absolute;
    top: -35px;
    right: -5px;
  }

  .cartItem__priceContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;

    p {
      margin-bottom: 0;
    }
  }
}