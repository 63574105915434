.productsGrid__container {
  h1.productGrid__title {
    margin-bottom: 40px;
    text-align: center;
    font-size: 36px;
  }
}

@media only screen and (max-width: 764px) {
  .productsGrid__container {
    padding: 0px 15px;
  }
}