@import 'assets/colours';

.product {
  margin-top: 100px;
}

.product__container {
  .product__image__container {
    width: 50%;
    max-height: calc(100vh - 100px);
    height: 1000px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    img {
      width: auto;
      max-width: none;
      height: 100%;
      position: absolute;
      left: -20%;
    }
  }
  
  .product__details_container {
    width: 50%;
    display: flex;
    justify-content: center;
    padding: 80px;
    box-sizing: border-box;
    flex-direction: column;

    .product__details_container {
      max-width: 500px;
    }
  }

  h1 {
    font-size: 46px;
    font-weight: 100;
    margin-bottom: 0;
    line-height: 1.2;
  }

  p.product__description {
    margin-bottom: 50px;
    font-size: 18px;
    font-weight: 100;
  }

  .variant__container {
    display: grid;
    grid-template-columns: repeat(5, 60px);
    row-gap: 20px;
    margin: 20px 0;
    margin-bottom: 100px;
  }

  button.product__addCartButton {
    margin-top: 40px;
    padding: 10px 40px;
    background: $elite-feature-yellow;
    border-bottom: 1px solid #000;
    font-weight: 700;
    transition: 0.2s;

    &:hover {
      background-color: $elite-feature-orange;
    }
  }
}

@media only screen and (max-width: 764px) {
  .product__container:global(.ant-row) {
    padding: 15px;

    h1 {
      font-size: 36px;
    }

    .product__details_container {
      width: 100%;
      padding: 0;
      order: 2;

      .product__details_container_inner {
        width: 100%;
      }
    }

    .product__image__container {
      height: 300px;
      width: 100%;
      margin-bottom: 30px;
    }
  }

  .similarStyle__container {
    padding: 0 15px;
    margin-top: 50px;
  }
}