header {
  width: 100vw;
  height: 100px;
  padding: 0px 80px;
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #FFF;
  z-index: 99;
}

@media only screen and (max-width: 764px) {
  header {
    padding: 0px 15px;
  }
}