.product__container {
  .product__image__container {
    width: 50%;
    display: flex;
    align-items: center;
  }
  .product__details_container {
    width: 50%;
    display: flex;
    align-items: center;
    padding: 80px;
    box-sizing: border-box;
    position: relative;

    div {
      max-width: 450px;
      margin: 0 auto;

      &.product__details_overflow_container {
        width: 100%;
        height: 450px;
        position: relative;
        // overflow: hidden;

        .product__details_animated {
          position: absolute;
          top: 0;
          opacity: 0;
        }
      }

      h1 {
        font-size: 56px;
        line-height: 1;
        font-weight: 100;
      }
    }

    .stepScrollBar {
      width: 5px;
      height: 200px;
      position: absolute;
      top: calc((100% - 200px) / 2);
      right: 80px;
      background-color: #E5E5E5;
      border-radius: 40px;
      overflow: hidden;

      .scrollPointer {
        width: 100%;
        height: 33.33%;
        position: absolute;
        top: 33.33%;
        left: 0;
        border-radius: 40px;
        background-color: #161717;
      }
    }
  }
}

@media only screen and (max-width: 764px) {
  .product__container:global(.ant-row) {
    flex-direction: column;

    .product__image__container {
      width: 100%;
    }

    .product__details_container {
      width: 100%;
      padding: 15px;
    }
  }
}