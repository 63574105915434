.cartItem__container {
  display: flex;
  align-items: center;
  width: 100%;
  height: 200px;
  padding: 20px 0;
  border-bottom: 1px solid #F4F4F4;

  div.cartItem__imageContainer {
    width: 450px;
    height: 100%;
    display: flex;
    align-items: center;
    overflow: hidden;
  }

  img {
    min-width: 100%;
    min-height: 100%;
  }

  div.cartItem__contentContainer {
    width: calc(100% - 450px);
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px 0;
    padding-left: 40px;

    button.cartItem__removeButton {
      position: absolute;
      top: 0;
      right: 0;
    }

    h1 {
      margin-bottom: 0;
      font-size: 24px;
      line-height: 1;
    }

    p.cartItem__variant {
      margin-bottom: 0;
    }
  }

  div.cartItem__priceContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    p {
      margin-bottom: 0;
    }

    p.cartItem__price {
      font-weight: 700;
    }
  }
}