.dimensionsContainer {
  display: flex;
  align-items: center;
  margin-bottom: 40px;

  p {
    font-family: operetta-12, serif;
    font-style: normal;
    font-size: 20px;
    margin: 0;
  }

  p.dimensionsContainer__x {
    margin-left: 15px;
    margin-right: 20px;
  }

  .dimensionTag {
    font-size: 16px;
    color: #cccccc;
    padding: 5px;
  }

  input {
    height: 46px;
    width: 120px;
  }
}

.dimensionsInput {
  margin-right: 5px;
}