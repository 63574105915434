@import 'assets/_colours';

.cartDrawer {
  .title {
    margin-bottom: 10px;
  }

  :global(.ant-drawer-body) {
    padding: 60px;
    overflow: hidden;
  }

  div.cartDrawer__productContainer {
    height: 70%;
    margin-bottom: 40px;
    padding-top: 35px;
    padding-right: 15px;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  p.cartDrawer__subtotal {
    text-align: right;
    margin-bottom: 80px;
  }

  div.cartDrawer__buttonContainer {
    float: right;
    font-size: 16px;
    font-family: futura-pt, sans-serif;

    button.cartDrawer__keepBrowsing {
      margin-right: 30px;
      font-size: 16px;
      font-weight: 400;
      border: none;
      background: none;
      box-shadow: none;
      transition: 0.2s;

      &:hover {
        color: $elite-feature-orange;
      }
    }

    a.cartDrawer__confirmButton {
      font-weight: 600;
      color: initial;
      border-bottom: 1px solid #000;
      transition: 0.2s;

      &:hover {
        color: $elite-feature-orange;
        border-color: $elite-feature-orange;
      }
    }
  }
}

@media only screen and (max-width: 764px) {
  .cartDrawer {
    :global(.ant-drawer-body) {
      padding: 40px;
    }

    div.cartDrawer__productContainer {
      padding-right: 0;
    }
  }
}